$color-title: #721c24;
$color-navbar: #eff3f6;
$input: #878787;
$input-active: #FFFFF;

$naranja-oscuro: #cf6f44;
$naranja-claro: #feece6;
$naranja: #136AC6;
$negro-dark: #000000;
$gris: #eff3f6;
$gris-dark: #b8b8b8;
$blanco: #ffffff;
$verde: #84be3e;
$verde-oscuro: #00a200;
$rojo: #de0000;

$gris-claro: #e2e2e2;

$orange1: #ffa06a;
$orange2: #fc8a64;

$blue1: #006eaa;
$blue2: #00abfc;

/*Colores del nuevo disenio*/
$yellow: #A6C0DB;
$negro: #282828;
$azul: #3a86ff;

/* COMPRALO */
$primary: #136AC6;
$primary2: #B1CBE7;
$secondary: #1b69a9;
$secondary2: #051529;
$negro: #29282828;
$negro2: #484848;
$negro-dark: #000000;
$negro-dark2: #515151;

.naranja-oscuro {
    color: $naranja-oscuro;
}
.naranja-claro {
    color: $naranja-claro;
}
.naranja {
    color: $naranja;
}
.negro {
    color: $negro;
}
.gris {
    color: $gris;
}
.gris-dark {
    color: $gris-dark;
}
.blanco {
    color: $blanco;
}
.azul {
    color: $azul;
}
.verde {
    color: $verde;
}
.verde-oscuro {
    color: $verde;
}
.rojo {
    color: $rojo;
}

.transparente {
    color: transparent;
}
