@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

.tree-title {
    font-family: 'Roboto', sans-serif;
    color      : #fff;
    font-weight: 600 !important;
    font-size  : 15px !important;
    background-color: #136AC6;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

input {
    font-size: 1em;
}

ol.tree {
    padding-left: 0px;
}

ol.tree input[type="checkbox"] {
    display: none;
}

li {
    list-style-type: none;
    color          : #282828;
    position       : relative;
    /* margin-left : -15px; */
}

li.parent ol {
    padding-left: 15px;
}

li .titulo {
    cursor               : pointer;
    display              : block;
    padding              : 5px 27px 5px 15px;
    font-family          : 'Mukta', sans-serif;
    color                : #282828;
    font-weight          : 400 !important;
    font-size            : 17px !important;
    /* iOS Safari */
    -webkit-touch-callout: none;
    /* Safari */
    -webkit-user-select  : none;
    /* Konqueror HTML */
    -khtml-user-select   : none;
    /* Old versions of Firefox */
    -moz-user-select     : none;
    /* Internet Explorer/Edge */
    -ms-user-select      : none;
    /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
    user-select          : none;
    display: flex;
}
li .titulo a {
    flex: 1;
}
li .titulo:hover {
    background-color: #DCDCDC;
}

li.ancestors .titulo {
    background-color: transparent;
    /* font-weight: 700 !important; */
    font-size: 16px !important;
}
li.ancestors .titulo:hover {
    background-color: #DCDCDC;
}

.tree .cantidad {
    color      : #3A86FF;
    font-family: 'Mukta', sans-serif;
    font-weight: 400 !important;
}

li.parent label {
    position       : absolute;
    right          : 0;
    width          : 30px !important;
    display        : flex;
    align-items    : center;
    justify-content: center;
    /* height: 100%; */
}

li.parent label::after {
    content    : "\f105";
    font-family: "FontAwesome";
    /* margin     : auto; */
    padding    : 10px 5px;
    line-height: 10px;
    font-size  : 18px;
}

li label:hover {
    /* background-color: #DCDCDC; */
    cursor: pointer;
}

li input {
    width   : 1em;
    height  : 1em;
    position: absolute;
    left    : -0.5em;
    top     : 0;
    opacity : 0;
    cursor  : pointer;
}

li input+label+div+ol>li {
    display       : none;
    /* margin-left: -14px !important; */
    padding-left  : 1px;
}

.titulo.active {
    background-color: #DCDCDC;
}

li.parent input[type=checkbox]:checked+label::after {
    content: "\f107";
}

li input:checked+label+div+ol>li {
    display: block;
    margin : 0 0 0.063em;
}

li input:checked+label+div+ol>li:first-child {
    margin: 0 0 0.125em;
}

@media only screen and (max-width: 1024px) {
    .tree-title {
        font-size: 18px !important;
    }
    li .titulo {
        font-size: 17px !important;
    }
}

@media only screen and (max-width: 768px) {
    .tree-title {
        font-size: 16px !important;
    }
    li .titulo {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 425px) {
    .tree-title {
        font-size: 18px !important;
    }
    li .titulo {
        font-size: 17px !important;
    }
}