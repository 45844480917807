@import "../../../../style/configs/color.css";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

/* BREADCRUMB */
.container-breadcrumb {
    background-color: #cccccc;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
}
.container-breadcrumb .breadcrumb {
    background-color: transparent;
    margin: 0;
    padding: 20px 10px;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    align-items: center;
}
.container-breadcrumb .breadcrumb .breadcrumb-item {
    text-transform: none !important;
    display: flex;
    align-items: center;
    font-family: "Mukta", sans-serif;
    font-weight: 400;
    color: #282828;
    letter-spacing: 0px;
    font-size: 17px;
    line-height: 0;
}
.breadcrumb-to .breadcrumb-item + .breadcrumb-item::before {
    /* content: url('../../../../assets/img/icons/breadcrumb.png'); */
    content: "";
    background-image: url("../../../../assets/img/icons/breadcrumb.png");
    background-size: 10px 12px;
    display: inline-block;
    width: 10px;
    height: 12px;
    margin-right: 5px;
}
.container-breadcrumb .breadcrumb .breadcrumb-item a {
    color: inherit;
    text-align: justify;
    text-decoration: none;
}
.container-breadcrumb .breadcrumb-item.active {
    font-weight: 800;
    text-align: justify;
}
.fondo-negro {
    background-color: black;
}
.derechos {
    font-size: 12px;
    color: $gris;
}
.container-detalle-tienda {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: calc(15% + 10px);
    margin-right: calc(15% + 10px);
    background-color: #ffffff;
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    padding-bottom: 50px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
}

.img-encabezado {
    max-height: 80px;
}

.titulo {
    color: #4d4d4d;
    font-size: 1rem;
    font-weight: bold;
}
.subtitulo {
    color: #4d4d4d;
    font-size: 0.7rem;
    font-weight: bold;
}

.bordes-rounded {
    border-radius: 0.5rem;
    /* border: 1px solid $gris-dark !important; */
}

.footer {
    background-color: $azul;
}

.container-footer {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    margin-left: 15%;
    margin-right: 15%;
}

.container-footer label {
    color: white;
    font-weight: bold;
}

.container-footer span {
    color: white;
    font-size: 10px;
    font-weight: bold;
}

/* Texto del slider */
.slider-text {
    color: $azul;
    font-size: 10px;
}

/* Boton Slider */
.text-sm {
    font-size: 12px !important;
}

.btn {
    padding: 0.2rem 0.8rem !important;
}

/* CSS del Slider
.rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: $gris-dark !important;
    height: 3px;
    border-radius: 6px;
}

.rc-slider-track {
    position: absolute;
    left: 0;
    height: 3px;
    border-radius: 6px;
    background-color: black !important;
}

.rc-slider-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -3px;
    cursor: grab;
    border-radius: 50%;
    border: none !important;
    background-color: black !important;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}

.rc-slider-handle:active {
    border-color: black !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.rc-slider-handle:hover {
    border-color: black !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: black !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
} */

.btn-carrito {
    cursor: pointer;
    left: 417px;
    width: 190px;
    height: 35px;
    border: 3px solid #2a9fff;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: left;
    font-family: "Mukta";
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2a9fff;
    opacity: 1;
}

.btn-carrito-detalle {
    cursor: pointer;
    left: 417px;
    width: 366px;
    height: 56px;
    border: 3px solid #136ac6;
    background: #136ac6;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-family: "Mukta";
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0px;
}

.btn-carrito-detalle:hover {
    background: #136ac6ce 50% 0% no-repeat padding-box;
    color: #282828;
    border: 0;
}

.img-detalle {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}

.img-Samll {
    width: 80% !important;
}

.slider-img {
    margin: auto;
    width: 20% !important;
    img {
        width: 100%;
        margin: 5px;
    }
}

.modea-Detalle {
    font-family: "Mukta";
    font-size: 38px;
    font-weight: bold;
    color: #3a86ff;
}

.content-img {
    padding: 28px 28px !important;
}

.content-img-text {
    padding: 48px 48px;
}

.content-img-text .detalle-titulo-producto {
    font-family: "Mukta";
    font-weight: 400;
    text-align: left;
    font-size: 25px;
    color: #282828;
}
.content-img-text .marca-producto {
    font-family: "Mukta";
    font-weight: 300;
    font-size: 20px;
    color: #282828;
}
.content-img-text .descripcion-producto {
    font-family: "Mukta";
    font-weight: 300;
    font-size: 18px;
    color: #282828;
}

.content-img-text .control-cantidad {
    margin-bottom: 15px;
}
.content-img-text .control-cantidad div {
    height: auto !important;
    width: auto !important;
}
.content-img-text .control-cantidad .btn-azul-venta {
    width: 44px;
    height: 42px;
    border-radius: 5px !important;
}
.content-img-text .control-cantidad .btn-azul-venta:hover {
    opacity: 0.8;
}

.content-img-text .control-cantidad input {
    font-family: "Mukta";
    font-weight: 500;
    font-size: 18px;
    height: 42px !important;
    width: 66px;
    margin: 0 10px;
    border-radius: 5px !important;
    border: 1px solid #b7b7b7 !important;
}

/* GALERIA */
.image-gallery-slide-wrapper .image-gallery-swipe img {
    width: 100% !important;
    height: 460px !important;
}
.image-gallery-content {
    display: flex;
    align-items: center;
}
.image-gallery-swipe {
    padding: 39px 6%;
    border: 1px solid #707070;
    border-radius: 12px;
    height: 60vh;
}
.image-gallery-slides {
    height: 100%;
}
.image-gallery-slides .image-gallery-slide {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-gallery-thumbnails-wrapper {
    padding: 0 15px;
}
.image-gallery-thumbnails-wrapper
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container {
    display: flex;
    flex-direction: column;
}
.image-gallery-thumbnails-wrapper
    .image-gallery-thumbnails
    .image-gallery-thumbnails-container
    button {
    border: 1px solid #707070;
    border-radius: 12px;
    margin: 5px;
    padding: 15px;
    width: 100px;
    height: 100px;
    overflow: hidden;
}
.image-gallery-custom-left-nav {
    background-color: transparent;
    border: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    /* filter: drop-shadow(0 2px 2px #1a1a1a); */
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-gallery-custom-right-nav {
    background-color: transparent;
    border: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    /* filter: drop-shadow(0 2px 2px #1a1a1a); */
    display: flex;
    align-items: center;
    justify-content: center;
}
.gallery-fullscreen-custom-button {
    background-color: #3a86ff;
    border: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    /* filter: drop-shadow(0 2px 2px #1a1a1a); */
    display: flex;
    align-items: center;
    justify-content: center;
    left: 15px;
    bottom: 15px !important;
    border-radius: 50%;
    padding: 10px !important;
}

.gallery-fullscreen-custom-button img {
    width: 25px;
}

.image-gallery-custom-left-nav img,
.image-gallery-custom-right-nav img {
    width: 30px;
    height: 50px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 100%;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: inherit !important;
    max-width: 100% !important;
    height: inherit;
}

.image-gallery-slide-wrapper {
    height: 60vh;
    width: 80%;
}
.image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides img {
    /* width : 60% !important; */
    /* height: inherit !important; */
    padding: 10px;
    vertical-align: middle;
    object-fit: contain !important;
    max-height: calc(60vh - 80px) !important;
}

/* FULL SCREEN GALERIA */
.fullscreen .image-gallery-slide-wrapper {
    height: auto !important;
    width: 100% !important;
}
div:fullscreen .image-gallery-slide-wrapper .image-gallery-swipe img {
    height: 80vh !important;
    width: 70% !important;
}

div:-moz-full-screen .image-gallery-swipe {
    width: 80% !important;
    height: 100vh !important;
    display: flex;
    border: 0 !important;
    align-items: center;
    justify-content: center;
}
div:-moz-full-screen .image-gallery-slide-wrapper .image-gallery-swipe img {
    height: 80vh !important;
    width: 70% !important;
}

div:-webkit-full-screen .image-gallery-swipe {
    width: 80% !important;
    height: 100vh !important;
    border: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
div:-webkit-full-screen .image-gallery-slide-wrapper .image-gallery-swipe img {
    height: 80vh !important;
    width: 70% !important;
}

div:fullscreen .image-gallery-swipe {
    width: 100% !important;
    height: 100vh !important;
    border: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
div:fullscreen .image-gallery-slide-wrapper .image-gallery-swipe img {
    max-height: 80vh !important;
    max-width: 70% !important;
}

@media only screen and (max-width: 1440px) {
    .container-detalle-tienda .btn-carrito-detalle {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .container-detalle-tienda {
        margin-left: calc(8% + 10px) !important;
        margin-right: calc(8% + 10px) !important;
    }
    .container-breadcrumb .breadcrumb {
        margin-left: 8% !important;
        margin-right: 8% !important;
    }
}

@media only screen and (max-width: 991px) {
    .container-detalle-tienda {
        margin: 25px 0px;
        padding: 0px !important;
    }
    .content-img {
        text-align: center !important;
    }
    .content-img,
    content-img-text {
        margin: 0px !important;
        padding: 15px !important;
    }
}

@media only screen and (max-width: 768px) {
    .container-detalle-tienda {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }
    .container-breadcrumb .breadcrumb {
        margin-right: 15px !important;
        margin-left: 15px !important;
        padding: 25px 0;
    }
    /* GALERIA */
    .image-gallery-content {
        flex-direction: column;
        margin-top: 15px;
    }
    .image-gallery-slide-wrapper {
        width: 100%;
    }
    .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails
        .image-gallery-thumbnails-container {
        flex-direction: row;
        margin-top: 15px;
    }
    .image-gallery-slide div img {
        height: 400px !important;
    }

    /* FULL SCREEN */
    div:-moz-full-screen .image-gallery-swipe {
        width: 100% !important;
    }
    div:-moz-full-screen .image-gallery-slide-wrapper .image-gallery-swipe img {
        width: 100% !important;
    }
    div:-webkit-full-screen .image-gallery-swipe {
        width: 100% !important;
    }
    div:-webkit-full-screen
        .image-gallery-slide-wrapper
        .image-gallery-swipe
        img {
        width: 100% !important;
    }
    div:fullscreen .image-gallery-swipe {
        width: 100% !important;
    }
    div:fullscreen .image-gallery-slide-wrapper .image-gallery-swipe img {
        width: 100% !important;
    }

    .fullscreen .gallery-fullscreen-custom-button {
        bottom: 50px !important;
    }
}

@media only screen and (max-width: 425px) {
    .container-breadcrumb .breadcrumb {
        padding: 25px 0px;
    }

    /* GALERIA */
    .image-gallery-swipe {
        padding: 29px 47px;
        margin-top: 15px;
    }
    .image-gallery-slide div img {
        height: 200px !important;
    }
    .image-gallery-thumbnails-wrapper {
        width: 100%;
        padding: 0;
    }
    .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails
        .image-gallery-thumbnails-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 15px;
    }
    .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails
        .image-gallery-thumbnails-container
        button {
        width: 80px;
        height: 80px;
        padding: 8px;
    }
    .container-detalle-tienda .control-cantidad {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-carrito-detalle {
        width: 100%;
    }
}

.minus {
    box-sizing: border-box;
    position: relative;
    display: block;
    /* transform: scale(var(--ggs, 1)); */
    color: #fff;
    width: 16px;
    height: 3px;
    background: white;
    border-radius: 15px;
}

.plus,
.plus::after {
    display: block;
    box-sizing: border-box;
    background: white;
    border-radius: 10px;
}
.plus {
    margin-top: -2px;
    position: relative;
    /* transform: scale(var(--ggs,1)); */
    width: 16px;
    height: 3px;
}
.plus::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 16px;
    top: -7px;
    left: 7px;
}

.arrows-expand-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    /* transform: scale(var(--ggs, 1)); */
    width: 14px;
    height: 14px;
    margin: 2px;
    box-shadow: -6px 6px 0 -4px, 6px -6px 0 -4px;
    color: white;
}

.arrows-expand-right::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 22px;
    top: -4px;
    left: 6px;
    transform: rotate(45deg);
    border-top: 9px solid;
    border-bottom: 9px solid;
}
