@import '../../../../style/configs/color.css';
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
.fondo-gris{
    background-color: $gris-dark;
}

/* BANNER */
.container-banner {
    position  : relative;
    margin    : 0 auto;
    width     : 100%;
    background: #E8E8E8 0% 0% no-repeat padding-box;
}
.banner_empty {
    width: 100%;
    height: 380px;
    display  : block;
    margin   : 0 auto;
}
.container-banner #PORTADA {
    width: 100%;
    height   : auto;
    display  : block;
    margin   : -10px auto auto auto;
}
.container-banner img {
    width: 100%;
    height   : auto;
    display  : block;
    margin   : auto auto;
}

/* INFO EMPRESA */
.container-info-empresa {
    position       : absolute;
    bottom: -107px;
    width          : 100%;
    padding-left   : 8%;
    padding-right  : 8%;
    display        : flex;
    align-items    : flex-end;
}

.container-info-empresa .container-logo {
    width           : 172px;
    height          : 172px;
    border-radius   : 50%;
    background-color: #FFFFFF;
    border          : 3px solid #D5D5D5;
    display         : flex;
    align-items     : center;
    justify-content : center;
    overflow        : hidden;
    margin: auto;
    margin-bottom: 29px;
}

.logo-default {
    width: 154px !important;
    height: auto !important;
    border-radius: 0 !important;
    opacity: 0.7 !important;
}

.container-info-empresa .container-logo img {
    max-width    : -webkit-fill-available;
    max-height   : -webkit-fill-available;
    width        : 180px;
    height       : 180px;
    border-radius: 50%;
}

.container-sin-logo {
    background-color: #DCDCDC !important;
}

.container-info-empresa .container-datos-empresa {
    padding-left    : 15px;
    width          : 100%;
    border-radius  : 12px;
    display        : flex;
    flex-direction: column;
    justify-content: center;
}
.container-info-empresa .container-datos-empresa .nombre-empresa {
    margin-left: 20px;
    font-weight: 900;
    font-family   : 'Mukta', sans-serif;
    margin: 10px 0;
    font-size: 35px;
    text-transform: uppercase;
}

.container-info-empresa .container-datos-empresa .container-items {
    background: #282828 0% 0% no-repeat padding-box;
    border-radius: 12px;
    height: 45px;
    width: 100%;
}
.container-info-empresa .container-datos-empresa .container-item-datos {
    display       : flex;
    align-items: center;
    padding: 5px 20px;
    font-family   : 'Mukta', sans-serif;
}

.container-info-empresa .container-datos-empresa .container-item-datos span {
    text-align    : left;
    font-family   : 'Mukta', sans-serif;
    font-weight: 800;
    letter-spacing: 0px;
    font-size: 17px;
    color: #FFFFFF;
    word-break: break-word;
}

.container-info-empresa .container-datos-empresa .container-item-datos .icono-datos-empresa {
    width: 24px !important;
    height: 20px !important;
    margin: inherit;
    margin-right: 15px;
    
}

.container-info-empresa .container-datos-empresa .info-separador {
    width     : 3px;
    height    : 78px;
    background: #282828 0% 0% no-repeat padding-box;
}

.fondo-negro{
    background-color: black;
}

.derechos{
    font-size: 10px;
    color: $gris;
}

.container-tienda {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    margin-top: 40px;
    font-size: 14px;
    margin-left: 8%;
    margin-right: 8%;
}

.img-encabezado{
    max-height: 80px;
}

.titulo{
    color: #282828 !important;
    font-size: 1rem;
    font-family: 'Mukta';
    font-weight: 800;
    font-size: 25px !important;
    font-weight: bold;
}
.subtitulo{
    color: #282828 !important;
    font-size: 0.7rem;
    font-weight: bold;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.bordes-rounded{
    border-radius: 0.5rem;
    /* border: 1px solid $gris-dark !important; */
}
#CARD-PRODUCTO {
    /* box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px !important;
    overflow: hidden;
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #707070 !important;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
}

.card img{
    max-height: 200px;
    height: 20vw;
    object-fit: contain !important;
    width: 100%;
}
.card-footer {
    width: 100%;
    background-color: #A6C0DB !important;
    border: 1px solid #A6C0DB !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 10px 10px 0 0 !important;
    margin-top: -10px;
}
.card-footer a {
    color: #282828;
    text-decoration: none;
}
.card-footer a:hover {
    color: #282828;
    text-decoration: none;
}

.footer{
    background-color: black;
}

.container-footer {
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    margin-left: 15%;
    margin-right: 15%;
}

.container-footer label{
    color: white;
    font-weight: bold;
}

.container-footer span{
    color: white;
    font-size: 10px;
    font-weight: bold;
}

/* Texto del slider */
.slider-text{
    color: #3A86FF !important;
    font-size: 14px !important;
    margin-bottom: 12px;
    font-family: 'Mukta';
    font-weight: 400;
}

/* Boton Slider */
.text-sm{
    font-size: 12px !important;
}

.btn {
    padding: .200rem .800rem !important;
}

/* CSS del Slider */
.rc-slider {
    margin: 0 5px;
    width: 95.5%;
}
.rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #AAA8A8 !important;
    height: 4px;
    border-radius: 6px;
}

.rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #136AC6 !important;
}

.rc-slider-handle {
    position: absolute;
    width: 18px;
    height: 18px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -7px;
    cursor: grab;
    border-radius: 50%;
    border: none !important;
    background-color: #136AC6 !important;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}

.rc-slider-handle:active {
    border-color: black !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.rc-slider-handle:hover {
    border-color: black !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: black !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.card-footer .btn-card {
    cursor         : pointer;
    width          : 45px;
    height         : 45px;
    border-radius  : 100%;
    position: absolute;
    top: -30px;
    right: 10px;
    box-shadow: 0px 3px 6px #00000029;
    outline:none !important;
    outline-width: 0 !important;
    border: none !important;
    padding: 0;
    margin: 0;
}

.btn-card:hover {
    background: #5795f8;
    border    : 0;
}

.btn-card:disabled {
    cursor: not-allowed;
}

.card-footer .titulo-producto {
    display           : block;
    /* Fallback for non-webkit */
    display           : -webkit-box;
    width             : 100% !important;
    font-size         : 17px;
    height            : calc(17px*1.4*2);
    /* Fallback for non-webkit */
    margin            : 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #fff;
    margin-bottom: -20px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.226);
}

.card-footer .titulo-producto.codigo {
    color: #136AC6;
    /* color: #BF3638; */
    text-shadow: none;
    font-weight: bold;
}

.card-footer .precio-producto {
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    font-size  : 20px;
    color: #000;
}

.txt-sub-familia {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size  : 14px;
    color: #fff;
    /* color: #BF3638; */
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.226);
}


/* FILTRO */
.txt-filtro {
    font-family: 'Roboto', sans-serif;
    color      : #FFFFFF;
    font-weight: 600 !important;
    font-size  : 15px !important;
    color: #57535A;
    background-color: #DDDCDD;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}
.container-btn-filtro {
    margin-top: 12px;
}

/* PAGINACION */
.pagination .page-item {
    margin-left: 2px;
    margin-right: 2px;

}
.pagination .page-item a {
    border-radius: 11px !important;
    width: 47px;
    height: 40px;
    color: #FFFFFF !important;
    font-size: 20px !important;
    line-height: 22px;
    background-color: #AAA8A8 !important;
    text-align: center;
    font-family: 'Mukta';
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-item.active .page-link {
    background-color: #136AC6 !important;
    border: none;
}
.page-item.disabled {
    opacity: 0.5 !important;
    cursor: not-allowed;
}

@media only screen and (max-width: 1200px) and (min-width: 1025px) {
    .container-info-empresa {
        padding-left: 4% !important;
        padding-right: 4% !important;
    }
    .container-tienda {
        /* margin-top: 100px; */
        margin-right: 4% !important;
        margin-left: 4% !important;
    }
    #LISTADO-PRODUCTOS .col-lg-3 {
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
    #LISTADO-PRODUCTOS .col-lg-3 {
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .container-info-empresa {
        padding-left: 2%;
        padding-right: 2%;
        bottom: -95px;
    }
    .container-tienda {
        margin-left: 2% !important;
        margin-right: 2% !important;
    }
    .banner_empty {
        height: 335px;
    }
    .container-banner #PORTADA {
        height: 335px !important;
    }
    .container-info-empresa .container-logo {
        width : 132px;
        height: 132px;
    }
    .container-info-empresa .container-datos-empresa .nombre-empresa {
        font-size: 25px;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos {
        padding: 0 10px;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos .icono-datos-empresa {
        width: 18px !important;
        height: 18px !important;

    }
    .container-info-empresa .container-datos-empresa .container-item-datos span {
        margin: 0;
    }
    .btn-card {
        font-size: 13px;
        width: 100%;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .container-tienda {
        margin-top: 20px;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .container-info-empresa {
        padding-left: 0 !important;
        padding-right: 0 !important;
        bottom: -86px !important;
    }
    .banner_empty {
        height: 235px;
    }
    .container-banner #PORTADA {
        height: 235px !important;
    }
    .logo-default {
        width: 120px !important;
        height: auto !important;
    }
    .container-info-empresa .container-logo {
        width : 160px;
        height: 160px;
        margin-bottom: 6px;
    }
    .container-info-empresa .container-datos-empresa .nombre-empresa {
        font-size: 18px;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos {
        padding: 0 10px;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos .icono-datos-empresa {
        width: 14px !important;
        height: 14px !important;

    }
    .container-info-empresa .container-datos-empresa .container-item-datos span {
        margin: 0;
    }
    .btn-card {
        font-size: 12px;
    }
}
@media only screen and (max-width: 767px) and (min-width: 425px) {
    #LISTADO-PRODUCTOS [class*="col-"] {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }
    .container-tienda {
        margin-right: 0;
        margin-left: 0;
        margin-top: 20px;
    }

    .banner_empty {
        height: 235px;
    }
    .container-banner #PORTADA {
        height: 235px !important;
        object-fit: cover;
        object-position: center center;
    }
    .container-info-empresa {
        display: block;
        align-items: center;
        height: auto;
        top: 145px;
        bottom: auto;
        padding-right: 0;
        padding-left: 0;
    }
    .container-info-empresa .container-logo{
        margin: auto;
        width: 155px;
        height: 155px;
    }
    .logo-default {
        width: 120px !important;
        height: auto !important;
    }
    .container-info-empresa .container-datos-empresa {
        margin-left: 0;
        margin-top: 10px;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 !important;
        align-items: center;
    }
    .container-info-empresa .container-datos-empresa .container-items {
        height: auto;
        width: 100% !important;
        flex-direction: column !important;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .container-info-empresa .container-datos-empresa .info-separador {
        display: none;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos {
        flex-direction: row;
        span {
            margin: 0;
            font-size: 14px;
        }
        .icono-datos-empresa {
            height: auto !important;
        }
    }
    .container-info-empresa .container-datos-empresa {
        padding: 15px 10px;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos {
        flex: auto;
    }
    .container-info-empresa .container-datos-empresa .nombre-empresa {
        /* Fallback for non-webkit */
        display           : -webkit-box;
        width             : 100% !important;
        font-size         : 25px;
        /* height            : calc(25px*1.4*2); */
        /* Fallback for non-webkit */
        margin            : 0 auto;
        line-height       : 1.4;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow          : hidden;
        text-overflow     : ellipsis;
        min-height        : 40px;
        text-align: center;
        margin: 15px 0;
    }
}

@media only screen and (max-width: 424px) and (min-width: 0px) {
    .swal2-actions button {
        width: 100%;
        margin: 15px 0;
    }
    .container-tienda {
        margin-right: 0;
        margin-left: 0;
        margin-top: 20px;
    }
    .card {
        margin    : 0 auto;
    }
    .card img {
        max-width: 100%;
        height   : auto !important;
        display  : block;
        margin   : 0 auto;
    }
    .container-banner {
        margin-bottom: 45px;
    }
    .btn-card {
        font-size: 17px;
    }
    .banner_empty {
        height: 235px;
    }
    .container-banner #PORTADA {
        height: 235px !important;
        object-fit: cover;
        object-position: center center;
    }
    .container-info-empresa {
        display: block;
        align-items: center;
        height: auto;
        top: 80px;
        bottom: auto;
        padding-right: 0;
        padding-left: 0;
    }
    .container-info-empresa .container-logo{
        margin: auto;
        width: 135px;
        height: 135px;
    }
    .logo-default {
        width: 120px !important;
        height: auto !important;
    }
    .container-info-empresa .container-datos-empresa {
        margin-left: 0;
        margin-top: 10px;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 !important;
        align-items: center;
    }
    .container-info-empresa .container-datos-empresa .container-items {
        height: auto;
        width: 100% !important;
        flex-direction: column !important;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .container-info-empresa .container-datos-empresa .info-separador {
        display: none;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos {
        flex-direction: row;
        span {
            margin: 0;
            font-size: 14px;
        }
        .icono-datos-empresa {
            height: auto !important;
        }
    }
    .container-info-empresa .container-datos-empresa {
        padding: 15px 10px;
    }
    .container-info-empresa .container-datos-empresa .container-item-datos {
        flex: auto;
    }
    
    .txt-filtro {
        font-size: 16px;
    }
    .titulo {
        font-size: 18px !important;
    }
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pagination .page-item a {
        width: 40px;
        height: 35px;
        font-size: 17px !important;
        line-height: 17px;
    }
    .pagination .page-item a img {
        width: 17px;
    }
    .container-info-empresa .container-datos-empresa .nombre-empresa {
        /* Fallback for non-webkit */
        display           : -webkit-box;
        width             : 100% !important;
        font-size         : 25px;
        height            : calc(25px*1.4*2);
        /* Fallback for non-webkit */
        margin            : 0 auto;
        line-height       : 1.4;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow          : hidden;
        text-overflow     : ellipsis;
        min-height        : 40px;
        text-align: justify;
        margin: 15px 0;
    }
}

/* contenedor card productos */
.card-producto-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px 50px;
    margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
    .card-producto-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 240px));
        grid-gap: 20px 50px;
        margin-bottom: 40px;
    }
}

.max-text-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;

    font-size         : 17px;
    text-overflow     : ellipsis;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.226);
}